@import "icon-variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('/#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?7i7b5x') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-git {
  &:before {
    content: $icon-git;     
    color: #f05032;
  }
}
.icon-github {
  &:before {
    content: $icon-github; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;     
    color: #1da1f2;
  }
}
.icon-mastodon {
  &:before {
    content: $icon-mastodon;     
    color: #3088d4;
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}

