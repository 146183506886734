$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-git: "\e900";
$icon-github: "\e901";
$icon-twitter: "\e902";
$icon-mastodon: "\e903";
$icon-email: "\e904";
$icon-menu: "\e905";

